import {createRouter, createWebHistory} from 'vue-router'

import {allRoutes} from './router'

const router = createRouter({
    // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    routes: allRoutes, // `routes: routes` 的缩写
})


export default router