// auth related routes
const authRoutes = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/router/views/pages/account/login'),
    }
]

// auth related routes
const authLoginRoutes = [
    {
        path: '/login',
        name: 'login2',
        component: () => import('@/router/views/pages/account/login'),
    }
]


// dashboard
const dashboardRoutes = [
    {
        path: '/home',
        name: '主页',
        header: 'Navigation',
        icon: 'home',
        component: () => import('@/router/views/pages/home/index'),
    },
]

// exam apply
const examApplyRoutes = [
    {
        path: '/exam/apply',
        name: '考试申请',
        children: [
            {
                path: 'index',
                name: '申请考试',
                component: () => import('@/router/views/pages/exam/apply/index')
            },
            {
                path: 'my',
                name: '我的申报',
                component: () => import('@/router/views/pages/exam/apply/my')
            },
            {
                path: 'edit',
                name: '编辑申报',
                component: () => import('@/router/views/pages/exam/apply/edit')
            },
            {
                path: 'detail',
                name: '申报详细',
                component: () => import('@/router/views/pages/exam/apply/detail/index.vue')
            },
            {
                path: 'planpublic',
                name: '合班计划',
                component: () => import('@/router/views/pages/exam/apply/planpublic')
            },
            {
                path: 'process',
                name: '审批申报',
                component: () => import('@/router/views/pages/exam/apply/process')
            }
        ]
    }
]

// exam list
const examListRoutes = [
    {
        path: '/exam/list',
        name: '考试列表',
        children: [
            {
                path: 'index',
                name: '考试列表',
                component: () => import('@/router/views/pages/exam/list/index')
            }
        ]
    }
]

// exam apply
const examPlanRoutes = [
    {
        path: '/exam/plan',
        name: '考试计划',
        children: [
            {
                path: 'index',
                name: '考试计划',
                component: () => import('@/router/views/pages/exam/plan/index')
            }
        ]
    }
]

const userRoutes = [{
    path: '/user',
    name: '用户管理',
    children: [
        {
            path: 'index',
            name: '用户管理',
            component: () => import('@/router/views/pages/user/index.vue')
        },
        {
            path: 'add',
            name: '添加用户',
            component: () => import('@/router/views/pages/user/add.vue')
        },
        {
            path: 'edit',
            name: '编辑用户',
            component: () => import('@/router/views/pages/user/edit.vue')
        }
    ]
}]


// my
const myRoutes = [
    {
        path: '/my',
        name: '我的',
        children: [
            {
                path: 'index',
                name: '个人中心',
                component: () => import('@/router/views/pages/my/index')
            }
        ]
    }
]


const exchangeRoutes = [{
    path: '/exchange',
    name: '交流大厅',
    children: [
        {
            path: 'index',
            name: '交流大厅',
            component: () => import('@/router/views/pages/exchange/index.vue')
        }
    ]
}]


// system
const systemApplyRoutes = [
    {
        path: '/system',
        name: '系统管理',
        children: [
            {
                path: 'set',
                name: '系统设置',
                component: () => import('@/router/views/pages/system/set')
            }
        ]
    }
]


const allRoutes = [...authRoutes, ...authLoginRoutes, ...dashboardRoutes, ...examApplyRoutes, ...examListRoutes, ...examPlanRoutes, ...userRoutes, ...myRoutes, ...exchangeRoutes]

export {allRoutes}