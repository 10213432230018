// store/index.js
import {createStore} from 'vuex';
import {reactive} from "vue";

const store = createStore({
    state() {
        return {
            // 初始状态
            userinfo: reactive({id: '', nickname: '', company: '', authority: ''})
        };
    },
    mutations: {
        // 同步状态变更函数
        setUser(state, userinfo) {
            for (const key in userinfo) {
                state.userinfo[key] = userinfo[key];
            }
        }

    },
    actions: {
        // 异步操作
    },
    getters: {
        // 对状态进行再计算
    }
});
export default store;
